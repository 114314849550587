<template>
  <div>
    <b-modal
      ref="cancel-approval-modal"
      hide-footer
      :titleHtml="modalTitle"
      id="modal-prevent-closing"
    >
      <b-form-group class="required-control">
        <label>Mô tả lý do hủy:</label>
        <b-form-textarea
          size="sm"
          v-model="$v.form.cancelReason.$model"
          :placeholder="'Thêm mô tả lý do hủy...'"
          :rows="6"
          :max-rows="6"
          :state="validateState('cancelReason')"
        ></b-form-textarea>
        <b-form-invalid-feedback id="input-live-feedback"
          >Vui lòng nhập lý do hủy</b-form-invalid-feedback
        >
      </b-form-group>
      <b-button
        style="fontweight: 600; width: 70px"
        variant="primary"
        size="sm"
        @click="onSubmit"
        >Lưu</b-button
      >
      <b-button
        style="margin-left: 10px; font-weight: 600; width: 70px"
        variant="secondary"
        size="sm"
        @click="hideModal"
        >Hủy</b-button
      >
    </b-modal>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import ApiService from '@/core/services/api.service';
import { makeToastSuccess, makeToastFaile } from '@/utils/common';

export default {
  props: ['approvalId', 'approvalCode', 'currentApproveStep'],
  mixins: [validationMixin],
  data() {
    return {
      modalTitle: '',
      form: {
        cancelReason: '',
      },
      onSubmiting: false,
    };
  },
  validations: {
    form: {
      cancelReason: { required },
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    showModal: function () {
      this.modalTitle = `<div>
              <span class='d-block' style='font-size: 1.1rem'>Duyệt phiếu: <a class='text-primary'>#${this.approvalCode}</a></span>
              </div>`;
      this.$refs['cancel-approval-modal'].show();
    },
    hideModal: function () {
      this.$refs['cancel-approval-modal'].hide();
    },
    onSubmit() {
      if (this.onSubmiting) {
        return;
      }
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      const data = {
        id: this.approvalId,
        currentApproveStep: this.currentApproveStep,
        cancelReason: this.$v.form.cancelReason.$model,
      };
      this.onSubmiting = true;
      ApiService.put('v2/approvals/cancel', data)
        .then(({ data }) => {
          makeToastSuccess(data.message);
          this.hideModal();
          this.$emit('cancel-success', true);
          this.onSubmiting = false;
        })
        .catch((err) => {
          if (!err.response) {
            // network error
            makeToastFaile(err.$error);
          } else {
            const message = err.response.data.message;
            makeToastFaile(message);
          }
          this.onSubmiting = false;
        });
    },
  },
};
</script>
